import React, { Component } from 'react'
import moment from 'moment'

import { Theme, Typography, LinearProgress, Button } from '@material-ui/core'
import { WithStyles, createStyles, withStyles } from '@material-ui/styles'

import { AutoSizer, MultiGrid as VirtualizedMultiGrid } from 'react-virtualized'
import 'react-virtualized/styles.css'

import { TelemetryType } from 'api/apiservice'
import {
  celsiusToFahrenheit,
  feetToFeetAndInches,
  getReadableTelemetryType,
  getTelemetryTypeUnit,
} from 'components/utils/converter'
import { HistoricalDataByTelemetryType } from 'store/HistoricalData'
import { ModelState } from 'store/ModelState'
import memoize from 'memoize-one'
import { HistoricalDataEntry, updateTelemetryDatapoint } from '../../api/client'
import InvalidDatapointDialog from '../details/InvalidData/InvalidDatapoint'
import { AppState } from '../../store/AppState'
import { connect } from 'react-redux'
import {
  hasSupervisorUserAccessLevel,
  isAdmin,
  isSystemAdmin,
} from 'store/oidc/userManager'
import { MultitenantUserState } from '../../store/reducers/multitenant'

const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      width: '100%',
      display: 'grid',
      alignItems: 'start',
      [theme.breakpoints.up('md')]: {
        width: '40vw',
      },
    },
    cell: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '0.5em',
      textAlign: 'center',
      borderBottom: '1px solid #e0e0e0',
    },
    cellInvalid: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '0.5em',
      textAlign: 'center',
      borderBottom: '1px solid #e0e0e0',
      color: 'red',
    },
    noCells: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1em',
      color: '#bdbdbd',
    },
  })

const GreyTextTypography = withStyles({
  root: {
    color: '#999999',
  },
})(Typography)

const rowNamesTotalFlow = [
  'Accumulated Volume',
  'Battery Volts',
  'Diff Pressure',
  'Static Pressure',
  'Temperature',
  'Today Volume Flowed',
  'Volume Flow Rate',
  'Yesterday Volume Flowed',
]

interface Props extends ModelState {
  readonly allHistoricalData: HistoricalDataByTelemetryType
  hideData: string[]
  readonly fullWidth?: boolean
  readonly onOpenDatapointInfo?: (currentData) => void
  readonly updateState: (id, validData) => string[]
}

interface PropsFromState {
  readonly user: MultitenantUserState
  readonly accessToken?: string
  readonly tenantId: number
  readonly email: string
}

interface CurrentData extends ModelState {
  readonly currData?: HistoricalDataEntry
  readonly showModal: boolean
  readonly hiddenData: string[]
}

type AllProps = Props & PropsFromState & WithStyles<typeof styles>

const rowHeight = 60
const fixedColumnCount = 1
const overscanColumnCount = 10
const scrollToColumn = Number.MAX_VALUE

class MeasuresTable extends Component<AllProps, CurrentData> {
  private readonly gridRef = React.createRef<VirtualizedMultiGrid>()
  private readonly memoizedData = memoize(
    (allHistoricalData: HistoricalDataByTelemetryType) => {
      const data = new Map<number, HistoricalDataEntry[]>()
      const telemetryData = Object.values(allHistoricalData)

      for (let i = 0; i < telemetryData.length; i++) {
        const telemetry = telemetryData[i]!

        telemetry.forEach((entry) => {
          const column = data.get(entry.timestamp) ?? []

          column[i] = entry

          data.set(entry.timestamp, column)
        })
      }

      const keys = Array.from(data.keys()).sort((a, b) => a - b)
      const sortedData = keys.map((k) => data.get(k))

      return sortedData
    }
  )

  constructor(props) {
    super(props)

    this.state = {
      currData: undefined,
      showModal: false,
      hiddenData: this.props.hideData,
    }

    this.renderHeaderCell = this.renderHeaderCell.bind(this)
    this.cellRenderer = this.cellRenderer.bind(this)
    this.renderRowCellName = this.renderRowCellName.bind(this)
    this.getColumnWidth = this.getColumnWidth.bind(this)
    this.noContentRenderer = this.noContentRenderer.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  isSystemAdmin = isSystemAdmin(this.props.user.selectedTenant?.roles)
  isAdmin = isAdmin(this.props.user.selectedTenant?.roles)
  validRole = hasSupervisorUserAccessLevel(
    this.props.user.selectedTenant?.roles
  )

  public openModal = (currentData) => {
    this.setState({
      currData: currentData,
      showModal: true,
    })
  }

  public closeModal = (id: string) => (ok: boolean) => {
    if (ok) {
      const currData = this.state.currData
      this.setValidDatapoint(
        currData?.id,
        currData?.telemetryType,
        currData?.invalidData,
        currData
      )
    } else {
      this.setState({
        showModal: false,
        currData: undefined,
      })
    }
  }

  async setValidDatapoint(
    id: any,
    telemetryType: TelemetryType | undefined,
    invalidData: boolean | undefined,
    currentData: HistoricalDataEntry | undefined
  ): Promise<void> {
    const { accessToken, tenantId, email } = this.props

    if (!accessToken || !tenantId) {
      return
    }

    const result = await updateTelemetryDatapoint(
      accessToken,
      tenantId,
      telemetryType,
      invalidData,
      email,
      id
    ) // .then((result) => {

    let updateId = ''
    const state = !currentData?.invalidData
    if (result) {
      const currentId = currentData?.id
      const itemFound = this.state.hiddenData.findIndex((t) => t === currentId)
      if (itemFound !== undefined && itemFound !== -1) {
        updateId = currentId
      } else {
        updateId = id
      }
    }
    const currState = this.props.updateState(updateId, state)
    this.setState({
      showModal: false,
      currData: undefined,
      hiddenData: currState,
    })
  }

  public render() {
    const { fullWidth, classes } = this.props

    const divStyle: React.CSSProperties | undefined = fullWidth
      ? { width: '100%' }
      : {}

    return (
      <div className={classes.container} style={divStyle}>
        {this.getMainBody()}
        {this.state.showModal && (
          <InvalidDatapointDialog
            currData={this.state.currData}
            onClose={this.closeModal(this.state.currData?.id)}
          />
        )}
      </div>
    )
  }

  private getMainBody(): React.ReactNode {
    const { loading, allHistoricalData } = this.props

    if (loading) {
      return <LinearProgress />
    }

    const historicalData = this.hideHistoricalData(
      allHistoricalData,
      this.state.hiddenData
    )
    const data = this.memoizedData(historicalData)

    const currentType: TelemetryType = Number(Object.keys(historicalData)[0])

    let rowCount, columnCount

    if (currentType === TelemetryType.TotalFlow) {
      const dataHistorical = historicalData[TelemetryType.TotalFlow] ?? [0]

      if (dataHistorical !== undefined) {
        rowCount = 9
        columnCount = data.length + 1
      }
    } else if (currentType === TelemetryType.PumpControl) {
      const dataHistoricalArray = historicalData[TelemetryType.PumpControl] ?? [
        0,
      ]

      const dataHistorical = dataHistoricalArray[dataHistoricalArray.length - 1]

      if (
        dataHistorical !== undefined &&
        dataHistorical.metaData !== undefined
      ) {
        const metadataEntries = Object.entries(dataHistorical.metaData)
        rowCount = metadataEntries.length + 1
        columnCount = data.length + 1
      }
    } else {
      rowCount = Object.keys(historicalData).length + 2
      columnCount = data.length + 1
    }

    const height = this.getTableHeight(rowCount, rowHeight)

    return (
      <AutoSizer disableHeight>
        {({ width }) => (
          <VirtualizedMultiGrid
            {...this.state}
            ref={this.gridRef}
            width={width}
            height={height}
            rowHeight={rowHeight}
            rowCount={rowCount}
            columnCount={columnCount}
            columnWidth={this.getColumnWidth}
            fixedColumnCount={fixedColumnCount}
            overscanColumnCount={overscanColumnCount}
            cellRenderer={this.cellRenderer}
            noContentRenderer={this.noContentRenderer}
            scrollToColumn={scrollToColumn}
            openModal={this.openModal}
            closeModal={this.closeModal}
          />
        )}
      </AutoSizer>
    )
  }

  public getHiddenData(historicalData): string[] {
    const currentTelemetry = Number(Object.keys(historicalData))
    const invalidIds = historicalData[currentTelemetry]
      .filter((x) => x.invalidData)
      .map((x) => x.id)
    return invalidIds
  }

  private hideHistoricalData(
    allHistoricalData: HistoricalDataByTelemetryType,
    hiddenData: string[]
  ): HistoricalDataByTelemetryType {
    const currentTelemetry = Number(Object.keys(allHistoricalData))
    if (
      allHistoricalData !== undefined &&
      allHistoricalData[currentTelemetry] !== undefined
    ) {
      allHistoricalData[currentTelemetry].forEach((element) => {
        if (hiddenData !== undefined) {
          if (hiddenData.some((x) => x === element.id)) {
            element.invalidData = true
          } else {
            element.invalidData = false
          }
        }
      })
    }
    return allHistoricalData
  }

  private cellRenderer({ columnIndex, key, rowIndex, style, parent }) {
    const { classes } = this.props
    const historicalData = this.hideHistoricalData(
      this.props.allHistoricalData,
      this.state.hiddenData
    )
    const currentType: TelemetryType = Number(Object.keys(historicalData)[0])
    if (columnIndex === 0 && currentType !== TelemetryType.PumpControl) {
      return this.renderRowCellName(rowIndex, key, style, currentType)
    } else if (columnIndex === 0 && currentType === TelemetryType.PumpControl) {
      return this.renderCellNameMetadata(
        historicalData,
        currentType,
        columnIndex,
        rowIndex,
        classes,
        key,
        style
      )
    } else if (rowIndex === 0) {
      return this.renderHeaderCell(columnIndex, key, style)
    } else if (currentType === TelemetryType.TotalFlow) {
      return this.getDataRowTotalFlow(
        historicalData,
        currentType,
        columnIndex,
        rowIndex,
        classes,
        key,
        style
      )
    } else if (currentType === TelemetryType.PumpControl) {
      return this.getDataRowMetadata(
        historicalData,
        currentType,
        columnIndex,
        rowIndex,
        classes,
        key,
        style
      )
    } else {
      const lastData = Object.keys(historicalData).length + 1

      if (rowIndex === lastData) {
        return this.editColumnCell(rowIndex, columnIndex, key, style, parent)
      }
      return this.renderRowCellValue(rowIndex, columnIndex, key, style)
    }
  }

  private editColumnCell(
    rowIndex: any,
    columnIndex: any,
    key: any,
    style: any,
    parent
  ): React.ReactNode {
    const historicalData = this.hideHistoricalData(
      this.props.allHistoricalData,
      this.state.hiddenData
    )
    const data = this.memoizedData(historicalData)

    const rowData = data[columnIndex - 1]

    if (!rowData) {
      return null
    }
    const currData = rowData[rowIndex - 2]

    if (!currData) {
      return null
    }

    return (
      <React.Fragment>
        {this.showWindowValidData(key, style, currData, parent)}
      </React.Fragment>
    )
  }

  private readonly showWindowValidData = (
    key,
    style,
    currData,
    parent
  ): React.ReactNode => {
    const { classes } = this.props
    const { openModal } = parent.props
    let labelButton: string

    if (this.isAdmin) {
      if (currData.invalidData) {
        labelButton = 'Unhide'
        return (
          <div className={classes.cellInvalid} key={key} style={style}>
            <Button
              fullWidth={true}
              variant="contained"
              color="primary"
              onClick={() => openModal(currData)}
            >
              {labelButton}
            </Button>
          </div>
        )
      } else {
        labelButton = 'Hide'
        return (
          <div className={classes.cell} key={key} style={style}>
            <Button
              fullWidth={true}
              variant="contained"
              color="primary"
              onClick={() => openModal(currData)}
            >
              {labelButton}
            </Button>
          </div>
        )
      }
    }
    return null
  }

  private getDataRowMetadata(
    allHistoricalData,
    currentType,
    columnIndex,
    rowIndex,
    classes,
    key,
    style
  ) {
    let value
    const dataHistorical = allHistoricalData[currentType][columnIndex - 1]

    if (dataHistorical && dataHistorical.metaData) {
      const entries = Object.entries(dataHistorical.metaData)

      if (entries.length > rowIndex - 1) {
        const [k, v] = entries[rowIndex - 1]
        value = v
      } else {
        value = '-'
      }
    } else {
      value = '-'
    }

    return (
      <div className={classes.cell} key={key} style={style}>
        {value}
      </div>
    )
  }

  private renderCellNameMetadata(
    allHistoricalData,
    currentType,
    columnIndex,
    rowIndex,
    classes,
    key,
    style
  ) {
    let value
    if (rowIndex === 0) {
      return (
        <div className={classes.cell} key={key} style={style}>
          <GreyTextTypography variant="subtitle2" align="center">
            {'Time'}
          </GreyTextTypography>
        </div>
      )
    }

    const dataHistoricalArray = allHistoricalData[currentType]

    const dataHistorical = dataHistoricalArray[dataHistoricalArray.length - 1]

    if (dataHistorical && dataHistorical.metaData) {
      const entries = Object.entries(dataHistorical.metaData)
      const [k, v] = entries[rowIndex - 1]
      value = k
    } else {
      value = '-'
    }

    // if (lastData === currData) {
    //   return (
    //     <>
    //       <div className={classes.cell} key={key} style={style}>
    //         <GreyTextTypography variant="subtitle2" align="center">
    //           {'Edit'}
    //         </GreyTextTypography>
    //       </div>
    //     </>
    //   )
    // }

    return (
      <div className={classes.cell} key={key} style={style}>
        <GreyTextTypography variant="subtitle2" align="center">
          {value}
        </GreyTextTypography>
      </div>
    )
  }

  private getDataRowTotalFlow(
    allHistoricalData,
    currentType,
    columnIndex,
    rowIndex,
    classes,
    key,
    style
  ) {
    let value
    const dataHistorical = allHistoricalData[currentType][columnIndex - 1]

    switch (rowIndex - 1) {
      case 0:
        value = dataHistorical.values.AccumulatedVolume ?? '0'
        break
      case 1:
        value = dataHistorical.values.BatteryVolts ?? '0'
        break
      case 2:
        value = dataHistorical.values.DiffPressure ?? '0'
        break
      case 3:
        value = dataHistorical.values.StaticPressure ?? '0'
        break
      case 4:
        value = dataHistorical.values.Temperature ?? '0'
        break
      case 5:
        value = dataHistorical.values.TodayVolumeFlowed ?? '0'
        break
      case 6:
        value = dataHistorical.values.VolumeFlowRate ?? '0'
        break
      case 7:
        value = dataHistorical.values.YesterdayVolumeFlowed ?? '0'
        break
      default:
        value = ''
        break
    }

    return (
      <div className={classes.cell} key={key} style={style}>
        {isNaN(+value) ? ' - ' : (+value).toFixed(2)}
      </div>
    )
  }

  private getTableHeight(rowCount: number, rowHeight: number): number {
    const rowsHeight = rowHeight * rowCount

    return rowCount <= 2 ? 220 : rowsHeight + 20
  }

  private noContentRenderer() {
    return <div className={this.props.classes.noCells}>No data available</div>
  }

  private getColumnWidth({ index }) {
    switch (index) {
      case 0:
        return 140
      default:
        return 85
    }
  }

  private renderHeaderCell(index: number, key, style): React.ReactNode {
    const { classes } = this.props
    const historicalData = this.hideHistoricalData(
      this.props.allHistoricalData,
      this.state.hiddenData
    )
    const data = this.memoizedData(historicalData)
    const currData = data[index - 1]
    const date = new Date(currData!.filter((t) => t.timestamp)[0]?.timestamp)

    return (
      <div className={classes.cell} key={key} style={style}>
        <GreyTextTypography variant="subtitle2" align="center">
          {this.getFormattedDate(date)}
        </GreyTextTypography>
      </div>
    )
  }

  private renderRowCellValue(
    rowIndex: number,
    columnIndex,
    key,
    style
  ): React.ReactNode {
    const { classes } = this.props
    const historicalData = this.hideHistoricalData(
      this.props.allHistoricalData,
      this.state.hiddenData
    )
    const data = this.memoizedData(historicalData)
    const rowData = data[columnIndex - 1]

    if (!rowData) {
      return null
    }
    const currData = rowData[rowIndex - 1]

    if (!currData) {
      return null
    }
    const currentType: TelemetryType = Number(
      Object.keys(historicalData)[rowIndex - 1]
    )

    const value: string = this.getRowCellValue(currentType, currData.value)

    if (
      currentType === TelemetryType.CasingPressure ||
      currentType === TelemetryType.TubingPressure
    ) {
      if (currData.invalidData) {
        return (
          <div className={classes.cellInvalid} key={key} style={style}>
            <GreyTextTypography variant="subtitle2" align="center">
              {value}
            </GreyTextTypography>
            <GreyTextTypography variant="caption" align="center">
              {currData.unitOfMeasure}
            </GreyTextTypography>
          </div>
        )
      } else {
        return (
          <div className={classes.cell} key={key} style={style}>
            <GreyTextTypography variant="subtitle2" align="center">
              {value}
            </GreyTextTypography>
            <GreyTextTypography variant="caption" align="center">
              {currData.unitOfMeasure}
            </GreyTextTypography>
          </div>
        )
      }
    }

    if (currData.invalidData) {
      return (
        <div className={classes.cellInvalid} key={key} style={style}>
          {value}
        </div>
      )
    } else {
      return (
        <div className={classes.cell} key={key} style={style}>
          {value}
        </div>
      )
    }
  }

  private getRowCellValue(currentType: TelemetryType, value): string {
    switch (currentType) {
      case TelemetryType.StaticTemp:
        return celsiusToFahrenheit(value).toFixed(2)

      case TelemetryType.TankLevel:
        const [tankLevelFt, tankLevelIn] = feetToFeetAndInches(value)
        return `${tankLevelFt.toFixed()}' ${tankLevelIn.toFixed(1)}"`
      default:
        return value.toFixed(2)
    }
  }

  private renderRowCellName(
    index: number,
    key,
    style,
    parentCurrentType: TelemetryType
  ): React.ReactNode {
    const { classes } = this.props
    const historicalData = this.hideHistoricalData(
      this.props.allHistoricalData,
      this.state.hiddenData
    )
    if (index === 0) {
      return (
        <div className={classes.cell} key={key} style={style}>
          <GreyTextTypography variant="subtitle2" align="center">
            {'Time'}
          </GreyTextTypography>
        </div>
      )
    }
    const lastData = Object.keys(historicalData).length

    const currData = index - 1

    const currentType: TelemetryType = Number(
      Object.keys(historicalData)[index - 1]
    )

    let rowName, rowUnits
    if (parentCurrentType === TelemetryType.TotalFlow) {
      rowName = rowNamesTotalFlow[index - 1]
      rowUnits = this.getTypeUnits(parentCurrentType, rowName)
    } else {
      rowName = getReadableTelemetryType(currentType)
      rowUnits = this.getTypeUnits(currentType)
    }

    if (lastData === currData) {
      return (
        <>
          <div className={classes.cell} key={key} style={style}>
            <GreyTextTypography variant="subtitle2" align="center">
              {'Edit'}
            </GreyTextTypography>
          </div>
        </>
      )
    }

    return (
      <div className={classes.cell} key={key} style={style}>
        <GreyTextTypography variant="subtitle2" align="center">
          {rowName}
        </GreyTextTypography>
        <GreyTextTypography variant="caption" align="center">
          {rowUnits}
        </GreyTextTypography>
      </div>
    )
  }

  private getTypeUnits(rowType: TelemetryType, labelName?: string): string {
    let rowUnits: string
    switch (rowType) {
      case TelemetryType.CasingPressure:
      case TelemetryType.TubingPressure:
        rowUnits = ''
        break
      case TelemetryType.TankLevel:
        rowUnits = 'feet'
        break
      case TelemetryType.TotalFlow:
        switch (labelName) {
          case 'Diff Pressure':
            rowUnits = 'PSI'
            break
          case 'Static Pressure':
            rowUnits = 'PSI'
            break
          case 'Temperature':
            rowUnits = 'F°'
            break
          case 'Battery Volts':
            rowUnits = 'V'
            break
          default:
            rowUnits = 'MCF'
        }

        break
      default:
        rowUnits = getTelemetryTypeUnit(rowType)
        break
    }
    return rowUnits
  }

  private getFormattedDate(date: Date): string {
    const locale = window.navigator.language
    moment.locale(locale)

    // Get locale data
    const localeData = moment.localeData()
    let format = localeData.longDateFormat('llll')
    // Remove year part
    format = format.replace(/YYYY/, '').replace(/ddd, /, '')

    return moment(date).format(format)
  }
}

const mapPropsFromState = ({ oidc, multitenantUser }: AppState) => ({
  accessToken: multitenantUser.accessToken,
  tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
  email: multitenantUser.email,
  user: multitenantUser,
})

export default connect(mapPropsFromState)(withStyles(styles)(MeasuresTable))
