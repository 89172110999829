import * as React from 'react'
import { TelemetryStatus, TelemetryType } from 'api/alertservice'
import { ProductionCode } from 'api/apiservice'
import { TotalFlowType } from '../sites/utils'
import { duration } from 'moment'
import { TimeRangeType } from 'utils/localStorage'
import { Typography } from '@material-ui/core'

export function inchToFeet(inch: number): number {
  return inch / 12
}

export function inchToFeetAndInches(inch: number): [number, number] {
  const ft = Math.trunc(inchToFeet(inch))
  const inches = inch - ft * 12.0

  return [ft, inches]
}

export function feetToFeetAndInches(feet: number): [number, number] {
  const ft = Math.trunc(feet)
  const inches = (feet - ft) * 12.0

  return [ft, inches]
}

export function feetAndInchesToInches(feet: number, inch: number) {
  return feet * 12 + inch
}

export function getTankStrapFactor(
  tankHeightFeet: number,
  tankCapacityBbl: number
) {
  return tankCapacityBbl / (tankHeightFeet * 48)
}

export function feetToBbl(
  valueFeet: number,
  tankHeightFeet: number,
  tankCapacityBbl: number
) {
  const strapFactor = getTankStrapFactor(tankHeightFeet, tankCapacityBbl)
  const valueQuarterInchIncrements = valueFeet * 12 * 4
  return valueQuarterInchIncrements * strapFactor
}

export function inchToBbl(
  valueInch: number,
  tankHeightFeet: number,
  tankCapacityBbl: number
) {
  const strapFactor = getTankStrapFactor(tankHeightFeet, tankCapacityBbl)
  const valueQuarterInchIncrements = valueInch * 4
  return valueQuarterInchIncrements * strapFactor
}

export function celsiusToFahrenheit(tempCelsius: number) {
  return (tempCelsius * 9.0) / 5.0 + 32.0
}

export function fahrenheitToCelsius(tempFahrenheit: number) {
  return ((tempFahrenheit - 32) * 5.0) / 9.0
}

export function millibarsToPsi(value: number) {
  return value * 0.0145038
}

export function getReadableTelemetryType(type?: TelemetryType) {
  switch (type) {
    case TelemetryType.TubingPressure:
      return 'Tubing Pressure'
    case TelemetryType.CasingPressure:
      return 'Casing Pressure'
    case TelemetryType.PumpPressure:
      return 'Pump Pressure'
    case TelemetryType.DiffPressure:
      return 'Differential Pressure'
    case TelemetryType.StaticPressure:
      return 'Static Pressure'
    case TelemetryType.TankLevel:
      return 'Tank Level'
    case TelemetryType.Flow:
      return 'Flow'
    case TelemetryType.StaticTemp:
      return 'Static Temperature'
    case TelemetryType.HeaterTemp:
      return 'Heater Temperature'
    case TelemetryType.CrankRevolutions:
      return 'Crank Revolutions'
    case TelemetryType.SeparatorPressure:
      return 'Separator Pressure'
    case TelemetryType.CompressorPressure:
      return 'Compressor Pressure'
    case TelemetryType.TurbineMeter:
      return 'Turbine Meter'
    case TelemetryType.GenericSensor:
      return 'Generic Sensor'
    case TelemetryType.StrokesPerMinute:
      return 'Strokes Per Minute'
    case TelemetryType.TotalFlow:
      return 'Total Flow'
    case TelemetryType.Vibration:
      return 'Vibration'
    case TelemetryType.KnockoutPressure:
      return 'Knockout Pressure'
    case TelemetryType.BalancedTank:
      return 'Balanced Tanks'
    case TelemetryType.FlareStatus:
      return 'Flare Status'
    case TelemetryType.PumpControl:
      return 'Pump Control'
    case TelemetryType.GatewayMetrics:
      return 'Gateway Metrics (Battery) '
    default:
      return '?'
  }
}

export function getSiteProperty(type?: TelemetryType) {
  switch (type) {
    case TelemetryType.TubingPressure:
      return 'tubingPressure'
    case TelemetryType.CasingPressure:
      return 'casingPressure'
    case TelemetryType.PumpPressure:
      return 'pumpPressure'
    case TelemetryType.StaticPressure:
      return 'staticPressure'
    case TelemetryType.TankLevel:
      return 'tanks'
    case TelemetryType.BalancedTank:
      return 'BalancedTanks'
    case TelemetryType.Flow:
    case TelemetryType.TotalFlow:
      return 'flowMeters'
    case TelemetryType.HeaterTemp:
      return 'heaters'
    case TelemetryType.CrankRevolutions:
      return 'crankRevolutions'
    case TelemetryType.SeparatorPressure:
      return 'separators'
    case TelemetryType.CompressorPressure:
      return 'compressors'
    case TelemetryType.TurbineMeter:
      return 'turbineMeters'
    case TelemetryType.StrokesPerMinute:
      return 'strokesPerMinute'
    case TelemetryType.KnockoutPressure:
      return 'knockoutPressures'
    default:
      return '?'
  }
}

export function getReadableTotalFlowType(type?: TotalFlowType) {
  switch (type) {
    case TotalFlowType.VolumeFlowRate:
      return 'Volume Flow Rate'
    case TotalFlowType.StaticPressure:
      return 'Static Pressure'
    case TotalFlowType.TodayFlowFlowed:
      return 'Today Flow Flowed'
    case TotalFlowType.YesterdayVolumeFlowed:
      return 'Yesterday Volume Flowed'
    case TotalFlowType.AccumulatedVolume:
      return 'Accumulated Volume'
    case TotalFlowType.BatteryVolts:
      return 'Battery Volts'
    case TotalFlowType.DiffPressure:
      return 'Diff Pressure'
    case TotalFlowType.Temperature:
      return 'Temperature'
    default:
      return '?'
  }
}

export function getTelemetryTypeUnit(
  type: TelemetryType,
  unitOfMeasure: string
) {
  switch (type) {
    case TelemetryType.TubingPressure:
    case TelemetryType.CasingPressure:
    case TelemetryType.DiffPressure:
    case TelemetryType.StaticPressure:
    case TelemetryType.SeparatorPressure:
    case TelemetryType.CompressorPressure:
    case TelemetryType.PumpPressure:
    case TelemetryType.KnockoutPressure:
      return unitOfMeasure ?? 'PSI'
    /* return 'psi' */
    case TelemetryType.TankLevel:
    case TelemetryType.BalancedTank:
      return 'feet'
    case TelemetryType.Flow:
    case TelemetryType.FlareStatus:
    case TelemetryType.PumpControl:
      return ''
    case TelemetryType.StaticTemp:
    case TelemetryType.HeaterTemp:
      return '°F'
    case TelemetryType.CrankRevolutions:
      return 'RPM'
    case TelemetryType.TurbineMeter:
      return 'BPH'
    case TelemetryType.TotalFlow:
      return 'MCF'
    case TelemetryType.StrokesPerMinute:
      return 'SPM'
    case TelemetryType.GatewayMetrics:
      return '%'
    case TelemetryType.Vibration:
      return 'W/M2'
    default:
      return '—'
  }
}

export function getReadableTelemetryStatus(type: TelemetryStatus) {
  return TelemetryStatus[type]
}

export function formatValue(
  telType: TelemetryType,
  value?: number,
  unitOfMeasure?: string
): React.ReactNode {
  if (value === undefined) {
    return 'N/A'
  }

  switch (telType) {
    case TelemetryType.TankLevel:
      const [tankLevelFt, tankLevelIn] = inchToFeetAndInches(value)
      return (
        <>
          {tankLevelFt.toFixed()}&apos; &nbsp;
          {tankLevelIn.toFixed(1)}&quot;
        </>
      )

    case TelemetryType.HeaterTemp:
    case TelemetryType.StaticTemp:
      value = celsiusToFahrenheit(value)
      break
    case TelemetryType.GatewayMetrics:
      const typeAlertColor = (value: number): string => {
        if (value <= 20) return '#ff0000'
        if (value <= 40 && value >= 21) return '#FFD146'

        return '#92d050'
      }

      return (
        <>
          <Typography style={{ color: typeAlertColor(value) }}>
            {value}
            &nbsp;
            {getTelemetryTypeUnit(telType, unitOfMeasure)}
          </Typography>
        </>
      )
  }

  return (
    <>
      {value.toFixed(2)}
      &nbsp;
      {getTelemetryTypeUnit(telType, unitOfMeasure)}
    </>
  )
}

export function formatValueTotalFlow(
  totalFlowType: TotalFlowType,
  value?: number
): React.ReactNode {
  if (value === undefined) {
    return 'N/A'
  }

  let telemetryTypeUnit: string

  switch (totalFlowType) {
    case TotalFlowType.StaticPressure:
      telemetryTypeUnit = 'PSI'
      break
    case TotalFlowType.DiffPressure:
      telemetryTypeUnit = 'PSI'
      break
    case TotalFlowType.Temperature:
      telemetryTypeUnit = 'F°'
      break
    case TotalFlowType.BatteryVolts:
      telemetryTypeUnit = 'V'
      break
    default:
      telemetryTypeUnit = 'MCF'
  }

  return (
    <>
      {(+value).toFixed(2)}
      &nbsp;
      {telemetryTypeUnit}
    </>
  )
}

export function getReadableProductionCode(prodCode?: ProductionCode) {
  switch (prodCode) {
    case ProductionCode.Oil:
      return 'Oil'

    case ProductionCode.Water:
      return 'Water'
  }

  return undefined
}

export function getFilteringDurationByTimeRangeType(value: TimeRangeType) {
  switch (value) {
    case TimeRangeType.lastInHour:
      return duration(1, 'hour')
    case TimeRangeType.lastInSixHours:
      return duration(6, 'hour')
    case TimeRangeType.lastInTwelveHours:
      return duration(12, 'hour')
    case TimeRangeType.lastInDay:
      return duration(1, 'day')
    case TimeRangeType.lastInThreeDays:
      return duration(3, 'day')
    case TimeRangeType.lastInSevenDays:
      return duration(7, 'day')
    case TimeRangeType.lastInFourteenDays:
      return duration(14, 'day')
    case TimeRangeType.lastInMonth:
      return duration(1, 'month')
    default:
      return duration(0)
  }
}

export function getTimeRangeTypeByDurationInHours(durationInHours: number) {
  switch (durationInHours) {
    case 1:
      return TimeRangeType.lastInHour
    case 6:
      return TimeRangeType.lastInSixHours
    case 12:
      return TimeRangeType.lastInTwelveHours
    case 24:
      return TimeRangeType.lastInDay
    case 72:
      return TimeRangeType.lastInThreeDays
    case 168:
      return TimeRangeType.lastInSevenDays
    case 336:
      return TimeRangeType.lastInFourteenDays
    case 720:
      return TimeRangeType.lastInMonth
    default:
      return TimeRangeType.lastInDay
  }
}
